.content-div {
  display: -ms-flexbox;
  display: flex;
  min-height: 100vh;
  height: auto;
}
.content-box {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 180px;
  box-sizing: content-box;
  max-width: 450px;
  width: 100%;
}
@media screen and (max-width: 850px) {
  .content-box {
    margin-top: 130px;
    width: 90%;
    max-width: 380px;
  }
}
.content-box .logo {
  display: none;
}
@media screen and (max-width: 850px) {
  .content-box .logo {
    display: block;
    margin: -85px auto 25px;
    max-width: 260px;
    width: 65%;
  }
}
.content-box .support-links {
  display: none;
}
@media screen and (max-width: 850px) {
  .content-box .support-links {
    width: inherit;
    text-align: center;
    margin: 35px auto;
    display: block;
  }
  .content-box .support-links > a {
    color: rgba(255, 255, 255, 0.7);
    display: contents;
    font-size: 12px;
    text-decoration: none;
    text-transform: uppercase;
  }
  .content-box .support-links > a svg.material-icons {
    font-size: 18px;
    margin-right: 8px;
    color: rgba(255, 255, 255, 0.7);
  }
  .content-box .support-links > a span {
    vertical-align: text-top;
  }
}
.content-box .paper {
  padding: 50px 40px 48px 40px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.3);
  margin-bottom: 25px;
  height: fit-content;
  border-radius: 6px;
}
@media screen and (max-width: 850px) {
  .content-box .paper {
    margin-bottom: 12px;
    padding: 33px 20px 33px 20px;
  }
}
.content-box .paper .header {
  margin-bottom: 44px;
}
@media screen and (max-width: 850px) {
  .content-box .paper .header {
    margin-bottom: 32px;
  }
}
.content-box .paper .header h1 {
  text-align: left;
  margin: 0;
  padding: 0;
  font-size: 28px;
  font-style: normal;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.87);
}
@media screen and (max-width: 850px) {
  .content-box .paper .header h1 {
    font-size: 26px;
  }
}
.content-box .paper .section {
  margin: 28px 0 28px 0;
}
.content-box .paper .section.hidden {
  display: none;
}
.content-box .paper .text-field input {
  background-color: #F5F5F5;
  font-size: 16px;
  font-family: Roboto, Calibri, Verdana, Arial, sans-serif;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.content-box .paper .remember-me {
  margin-top: -24px !important;
}
.content-box .paper .remember-me.ie9 label {
  display: inline-block;
}
.content-box .paper .remember-me.ie9 span {
  display: inline;
  margin-left: 6px;
}
.content-box .paper .login-button {
  -webkit-border-radius: 2px 2px 2px 2px;
  -moz-border-radius: 2px 2px 2px 2px;
  border-radius: 2px 2px 2px 2px;
  background-color: #a8d100;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.16);
  color: #ffffff;
}
.content-box .paper .footer-link {
  font-size: 12px;
  text-align: center;
  vertical-align: center;
}
.content-box .paper .footer-link .selectable:hover {
  cursor: pointer;
}
.content-box .paper .link {
  color: #009df0;
  text-decoration: none;
}
.content-box .menu-bar {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 6px;
}
.content-box .menu-bar a {
  color: #ffffff;
  font-size: 14px;
  text-decoration: none;
  display: inline-block;
}
@media screen and (max-width: 850px) {
  .content-box .menu-bar a {
    font-size: 12px;
  }
}
.content-box .menu-bar > div {
  color: white;
  display: inline-block;
}
.support-box {
  background: transparent linear-gradient(180deg, #006FAD 0%, #004d6e 56%, #004d6e 100%) 0% 0% no-repeat padding-box;
}
@media screen and (min-width: 850px) {
  .support-box {
    width: 256px;
    min-height: 100vh;
    display: -ms-flexbox;
    display: flex;
    float: left;
  }
  .support-box.ie9 {
    position: absolute;
    top: 0;
  }
}
@media screen and (max-width: 850px) {
  .support-box {
    display: none;
  }
}
.support-box .support-inner-box {
  margin-top: 180px;
  width: 84%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 850px) {
  .support-box .support-inner-box {
    margin-top: 80px;
  }
}
.support-box .support-inner-box .logo {
  display: block;
  width: 78px;
  margin-left: auto;
  margin-right: auto;
}
.support-box .support-inner-box h1 {
  text-align: center;
  padding: 0;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
}
.support-box .support-inner-box .support-text {
  display: block;
  color: #ffffff;
  text-align: center;
  width: inherit;
  word-wrap: break-word;
  margin-left: auto;
  margin-right: auto;
  font-size: 16px;
}
.support-box .support-links {
  width: inherit;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.support-box .support-links > a {
  color: #ffffff;
  display: contents;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
}
.support-box .support-links > a svg.material-icons {
  font-size: 48px;
  color: #009df0;
  display: block;
  margin-bottom: 4px;
  margin-right: auto;
  margin-left: auto;
}
html,
body,
body > div,
body > div > div,
body > div > div > div {
  margin: 0;
}
body {
  max-height: 100vh;
  background-color: #009df0;
  background: linear-gradient(180deg, rgba(0, 157, 240, 0.52), rgba(3, 150, 218, 0.67), rgba(17, 122, 192, 0.91), rgba(4, 85, 125, 0.93)), #0DA2E1 url(/images/login/dots-and-lines-inverted.svg) no-repeat;
  background-position: 130px 0px;
  background-attachment: fixed;
  background-size: 120%;
  background-blend-mode: normal;
}
.page-contents {
  position: relative;
  padding-left: 64px;
  padding-right: 64px;
  min-height: 290px;
  min-width: 400px;
}
window,
body,
p,
a,
div,
span,
*,
label,
input {
  font-family: Roboto, Calibri, Verdana, Arial, sans-serif;
}
.page-content-slim {
  width: inherit;
}
.user-chip > div {
  background: none;
  color: #009df0;
}
.rules-list > ul > li {
  margin-bottom: 10px;
}
.rules-list > ul > li > ul > li {
  margin-top: 8px;
}
